// Generated by Framer (c01e615)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {uHCv4OxEi: {hover: true}};

const cycleOrder = ["uHCv4OxEi"];

const variantClassNames = {uHCv4OxEi: "framer-v-1w4f35a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, tap, title, width, ...props}) => { return {...props, emRdwJQmv: title ?? props.emRdwJQmv ?? "Send It Here", pY4vadkIY: image ?? props.pY4vadkIY ?? {src: new URL("assets/512/594o60NB6jcpo1o0ZWps8OGYDM.png", import.meta.url).href, srcSet: `${new URL("assets/512/594o60NB6jcpo1o0ZWps8OGYDM.png", import.meta.url).href} 512w, ${new URL("assets/1024/594o60NB6jcpo1o0ZWps8OGYDM.png", import.meta.url).href} 1024w, ${new URL("assets/594o60NB6jcpo1o0ZWps8OGYDM.png", import.meta.url).href} 1112w`}, qJVYRm64w: tap ?? props.qJVYRm64w} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;image?: {src: string; srcSet?: string};tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, emRdwJQmv, pY4vadkIY, qJVYRm64w, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "uHCv4OxEi", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap4sddy4 = activeVariantCallback(async (...args) => {
if (qJVYRm64w) {
const res = await qJVYRm64w(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-90uRL", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "stretch", sizes: "min(329.5px, 100vw)", ...toResponsiveImage(pY4vadkIY)}} className={cx("framer-1w4f35a", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"uHCv4OxEi"} onTap={onTap4sddy4} ref={ref} style={{borderBottomLeftRadius: 166.98, borderBottomRightRadius: 166.98, borderTopLeftRadius: 166.98, borderTopRightRadius: 166.98, ...style}} {...addPropertyOverrides({"uHCv4OxEi-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBSZWd1bGFy", "--framer-font-family": "\"Obviously Regular\", sans-serif", "--framer-font-size": "20px", "--framer-letter-spacing": "-1px", "--framer-line-height": "100%", "--framer-text-alignment": "right", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Send It Here</motion.p></React.Fragment>} className={"framer-c0112z"} data-framer-name={"Send It Here"} fonts={["CUSTOM;Obviously Regular"]} layoutDependency={layoutDependency} layoutId={"JsKq_8d5q"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={emRdwJQmv} verticalAlignment={"center"} withExternalLayout/></Image></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-90uRL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-90uRL .framer-1rnpdep { display: block; }", ".framer-90uRL .framer-1w4f35a { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 17px; height: min-content; justify-content: center; overflow: visible; padding: 16px 100px 20px 100px; position: relative; width: min-content; }", ".framer-90uRL .framer-c0112z { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-90uRL .framer-v-1w4f35a .framer-1w4f35a { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-90uRL .framer-1w4f35a { gap: 0px; } .framer-90uRL .framer-1w4f35a > * { margin: 0px; margin-left: calc(16.697588px / 2); margin-right: calc(16.697588px / 2); } .framer-90uRL .framer-1w4f35a > :first-child { margin-left: 0px; } .framer-90uRL .framer-1w4f35a > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 329.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"P7aCyPaTb":{"layout":["auto","auto"]}}}
 * @framerVariables {"emRdwJQmv":"title","pY4vadkIY":"image","qJVYRm64w":"tap"}
 */
const FramerBrkBEi7ri: React.ComponentType<Props> = withCSS(Component, css, "framer-90uRL") as typeof Component;
export default FramerBrkBEi7ri;

FramerBrkBEi7ri.displayName = "BTN-Primary";

FramerBrkBEi7ri.defaultProps = {height: 56, width: 329.5};

addPropertyControls(FramerBrkBEi7ri, {emRdwJQmv: {defaultValue: "Send It Here", displayTextArea: false, title: "Title", type: ControlType.String}, pY4vadkIY: {__defaultAssetReference: "data:framer/asset-reference,594o60NB6jcpo1o0ZWps8OGYDM.png?originalFilename=Link-CTA.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, qJVYRm64w: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerBrkBEi7ri, [{family: "Obviously Regular", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/BrkBEi7ri:default", url: "assets/QXCC6HITqYj9vcduhtsg1xYfvA.woff"}, url: new URL("assets/QXCC6HITqYj9vcduhtsg1xYfvA.woff", import.meta.url).href}])